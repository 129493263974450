import {
  NARRATIVE_TYPES,
  NARRATIVE_TYPES_ARRAY,
  getNarrativeSlug,
  getNarrativeBySlug,
} from "posts/constants";

import RAW_CHART_DATA from "narratives/raw_chart_data.csv";
import { getNarrativeMilestones } from "utils/narratives";

import uniq from "lodash.uniq";
import { parseISOLocal } from "utils/date";

export const ALL_NARRATIVE_MEDIA_TYPES = [
  {
    slug: "tv_ent",
    name: "TV Entertainment",
    active: true,
  },
  {
    slug: "tv_news",
    name: "TV News",
    active: true,
  },
  {
    slug: "news",
    name: "Online News",
    active: true,
  },
  {
    slug: "twitter",
    name: "Twitter",
    active: true,
  },
];

export const NARRATIVE_MEDIA_TYPES = ALL_NARRATIVE_MEDIA_TYPES.filter(
  ({ active }) => active
);

export const getNarrativeMediaTypeProperty = ({ filter, property }) =>
  NARRATIVE_MEDIA_TYPES.find((narrativeMediaType) => {
    filter.entries.every(([k, v]) => narrativeMediaType[k] === v);
  })[property];

const underscoreSlug = (slug) => slug.replace(/-/g, "_");
const slugFromUnderscoreSlug = (slug) => slug.replace(/_/g, "-");

export const datesFromRawData = (data) =>
  uniq((data || RAW_CHART_DATA).map(({ date }) => parseISOLocal(date))).sort();

export const processChartData = (data) => {
  const narrativeMilestones = getNarrativeMilestones();

  return data.map(({ date, media, narrative, minutes }) => {
    const parsedDate = parseISOLocal(date);
    const narrativeType = getNarrativeBySlug(slugFromUnderscoreSlug(narrative));

    const hasInsights = narrativeMilestones.some(
      ({ narrative: milestoneNarrative, timelineDate }) =>
        narrativeType === milestoneNarrative && timelineDate === parsedDate
    );

    return {
      narrativeType,
      medium: media,
      date,
      parsedDate,
      hasInsights,
      count: minutes,
    };
  });
};

export const allChartData = () => processChartData(RAW_CHART_DATA);

export const filteredChartData = (
  { narrativeType: filterNarrative, date: filterDate, medium: filterMedium },
  data
) => {
  data = data || allChartData();
  filterNarrative =
    typeof filterNarrative == "string" ? [filterNarrative] : filterNarrative;
  filterMedium =
    typeof filterMedium == "string" ? [filterMedium] : filterMedium;

  const { after, before } = filterDate || {};

  return data.filter(({ narrativeType, date, medium }) => {
    if (filterNarrative && !filterNarrative.includes(narrativeType))
      return false;

    if (filterMedium && !filterMedium.includes(medium)) return false;

    if (after && date < after) return false;
    if (before && date > after) return false;

    return true;
  });
};

export const getChartData = ({ narrativeTypes: narratives, media }, data) => {
  data = data || processChartData(RAW_CHART_DATA);
  narratives = typeof narratives == "string" ? [narratives] : narratives;
  media = typeof media == "string" ? [media] : media;
  return data
    .reduce(
      (
        accumulator,
        { narrativeType, medium, count, date, parsedDate, hasInsights }
      ) => {
        if (narratives && !narratives.includes(narrativeType))
          return accumulator;
        if (media && !media.includes(medium)) return accumulator;

        let found = false;

        let chartData = accumulator.map((dataPoint) => {
          if (dataPoint.date !== date) return dataPoint;
          if (dataPoint.narrativeType != narrativeType) return dataPoint;
          if (dataPoint.date !== date) return dataPoint;
          found = true;

          return {
            ...dataPoint,
            count:
              count === null || dataPoint.count === null
                ? null
                : dataPoint.count + count,
          };
        });

        if (found) return chartData;
        else
          return [
            ...accumulator,
            {
              date,
              parsedDate,
              narrativeType,
              hasInsights,
              count,
            },
          ];
      },
      []
    )
    .sort(({ date: dateA }, { date: dateB }) => {
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    });
};
