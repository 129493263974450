import { datesFromRawData } from "narratives/constants";
import { format } from "date-fns";
import { parseISOLocal } from "utils/date";

export const getNarrativeMilestones = (narrative) => {
  const glob = require("glob");
  const allMilestones = glob.sync("./_povertyNarrativeMilestone/*.json");
  const fs = require("fs-extra");

  const timelineDates = [...datesFromRawData()].reverse();

  const milestones = allMilestones.map((milestone) => {
    var content = fs.readFileSync(milestone, "utf8");
    const milestoneData = { ...JSON.parse(content), filePath: milestone };
    const milestoneDate = parseISOLocal(milestoneData.date);

    const timelineDate = timelineDates.find((date) => {
      return date < milestoneDate;
    });

    const mediaArtifacts = milestoneData.mediaArtifacts.map(
      (mediaArtifactPath) => {
        let mediaArtifactContent = fs.readFileSync(
          mediaArtifactPath.substring(1),
          "utf8"
        );
        return {
          ...JSON.parse(mediaArtifactContent),
          filePath: mediaArtifactPath,
        };
      }
    );

    return { ...milestoneData, timelineDate, mediaArtifacts };
  });

  if (narrative)
    return milestones.filter(({ narrative: milestoneNarrative }) =>
      [].concat(narrative).includes(milestoneNarrative)
    );

  return milestones;
};
